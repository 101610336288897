/*
***************************************
Utility functions 
***************************************
*/

// prettier-ignore
export { 
  consoleMsg, 
  setMenu, 
  setHeader,
  setFooter, 
  setInspector,
  isVisible, 
  resetTextValue, 
  updateOfflineStatus, 
  selectRadios, 
  populateTable, 
  addTableSkeletons 
};

// Define function for toggling console messages
function consoleMsg(...args) {
	//if (app.appOptions.isDevelopment === true) {
	console.log(...args);
	return true;
	//}
}

/*function consoleMsg(type, ...args) {
	if (app.appOptions.isDevelopment === true) {
		const styles = {
			debug: "color: blue; background-color: lightgray; font-weight: bold;",
			info: "color: green; background-color: lightgray;",
			warn: "color: orange;",
			error: "color: red; background-color: lightgray; font-weight: bold;",
		};

		if (styles[type?.toLowerCase()]) {
			// Apply styling for recognized types
			console.log(`%c${type}:`, styles[type.toLowerCase()], ...args);
		} else if (args.length > 0) {
			// No recognized type, but there are args → log normally without extra prefix
			console.log(...[type, ...args].filter((arg) => arg !== undefined));
		}
	}
}*/

async function loadScript(scr) {
	let script = document.createElement("script");
	script.type = "text/javascript";
	script.src = scr;
	let myScript = document.querySelector(`script[src="${scr}"]`);
	if (myScript) {
		consoleMsg("Script already loaded...");
		consoleMsg("Form = ", form);
	} else {
		document.head.appendChild(script);
		consoleMsg("Loading " + script.src);
	}
}

async function unloadScript(scr) {
	let script,
		myHead = document.head;
	script = document.querySelector("script[src=" + CSS.escape(scr) + "]");
	myHead.removeChild(script);
	consoleMsg("Removed script ", src);
}

async function setMenu(vendor = undefined) {
	let templateContent = "",
		myMenu = "templates/menu-template.html";

	// Swap out menu if vendor is Mercedes
	if (vendor === "Mercedes") {
		myMenu = "templates/mercedes-menu-template.html";
	}

	await loadHTML(myMenu, templateContent)
		.then((content) => {
			templateContent = content;
		})
		.then(() => {
			const myTemplate = document.getElementById("menu");
			myTemplate.innerHTML = templateContent;
		});
}

async function setFooter(name) {
	let templateContent = "",
		myFooter = "templates/footer-template.html";
	await loadHTML(myFooter, templateContent)
		.then((content) => {
			templateContent = content;
		})
		.then(() => {
			const myTemplate = document.querySelector(`[name="${name}"]`);
			consoleMsg("TEMPLATE = ", myTemplate);
			myTemplate.innerHTML = templateContent;
		});
}

async function setHeader(name, inspector) {
	let templateContent = "",
		myHeader = "templates/header-template.html";
	await loadHTML(myHeader, templateContent)
		.then((content) => {
			templateContent = content;
		})
		.then(() => {});
	const myTemplate = document.getElementById(name);
	consoleMsg("TEMPLATE = ", myTemplate);
	myTemplate.innerHTML = templateContent;
	const userNameElement = myTemplate.querySelector("#inspector-name");
	if (userNameElement) {
		consoleMsg("User name element = ", userNameElement);
		/* This wasn't working using document.getElementById - robot suggested this approach (see ChatGPT log
		'DOM Manipulation Fix)*/
		userNameElement.innerHTML = inspector;
	} else {
		console.error("Element with ID 'inspector-name' not found in the template.");
	}
}

function setInspector(inspector) {
	const inspectorNameElement = document.getElementById("inspector-name");
	if (inspectorNameElement) {
		inspectorNameElement.innerHTML = inspector;
	} else {
		console.error("Element with ID 'inspector-name' not found in the template.");
	}
}

/*
async function setFooter() {
	const myFooter = "templates/footer-template.html";

	try {
		const templateContent = await loadHTML(myFooter);
		console.log("Loaded footer content:", templateContent);

		const myTemplate = document.getElementById("footer");
		if (!myTemplate) {
			console.error("Footer element not found in DOM");
			return;
		}

		myTemplate.innerHTML = templateContent; // Populate footer content
		console.log("Footer content updated:", myTemplate.innerHTML);
	} catch (error) {
		console.error("Error loading footer template:", error);
	}
}

*/

function getText() {
	fetch("page1.html").then((response) => {
		consoleMsg("Fetched text:");
		consoleMsg(response);
	});
}

function populateTable(tableId, dataObject) {
	// Get the table's <tbody> element by ID
	const tableBody = document.querySelector(`#${tableId}`);

	// Check if table exists
	if (!tableBody) {
		console.error(`Table with ID '${tableId}' not found.`);
		return;
	}

	// Clear existing content (placeholders included)
	tableBody.innerHTML = "";

	// Iterate through the object's keys and values
	for (const [key, value] of Object.entries(dataObject)) {
		// Create a new <tr> element
		const newRow = document.createElement("tr");

		// Create and configure the "Key" <td>
		const keyCell = document.createElement("td");
		keyCell.textContent = key;
		keyCell.classList.add("inspectionKey");

		// Create and configure the "Value" <td>
		const valueCell = document.createElement("td");
		valueCell.textContent = value;
		valueCell.classList.add("inspectionValue");

		// Append the cells to the row
		newRow.appendChild(keyCell);
		newRow.appendChild(valueCell);

		// Append the row to the table body
		tableBody.appendChild(newRow);
	}
}

function addTableSkeletons(tableId, rowCount) {
	const table = document.querySelector(`#${tableId}`);
	if (!table) return;

	for (let i = 0; i < rowCount; i++) {
		const row = document.createElement("tr");

		const keyPlaceholder = document.createElement("td");
		keyPlaceholder.innerHTML = `<ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>`;
		keyPlaceholder.style.padding = "8px";
		keyPlaceholder.style.border = "1px solid #ccc";

		const valuePlaceholder = document.createElement("td");
		valuePlaceholder.innerHTML = `<ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>`;
		valuePlaceholder.style.padding = "8px";
		valuePlaceholder.style.border = "1px solid #ccc";

		row.appendChild(keyPlaceholder);
		row.appendChild(valuePlaceholder);
		table.appendChild(row);
	}
}

function isVisible(element) {
	return !!element.offsetParent;
}

// Reset text area value (AKA [formerly] alert2)

/* Function that displays alert for confirmation to reset (delete) value of associated text area.  If canceled,
 associated ion-radio should be reset.  */

function resetTextValue(dialog, textId, divId) {
	const divElement = document.getElementById(divId);
	// Build query selector string to retrieve any children img elements
	const photos = document.querySelectorAll("#" + divId + " img");
	const textArea = document.getElementById(textId);
	if (dialog) {
		dialog.message = "Default";
		// Set alert message based on whether text area has a value, photos have been taken, or both
		if (photos.length > 0 && textArea.value) {
			consoleMsg("photos.length = ", photos.length);
			dialog.message =
				"You previously entered a description of observed damage for this component\
       and took photos of the damage.  Changing this radio button option from 'Issue' to 'OK' will delete \
       your previously entered text and photos.  Proceed with deletion?";
		} else if (photos.length > 0) {
			dialog.message =
				"You previously took photos of observed damage for this component. \
       Changing this radio button option from 'Issue' to 'OK' will delete your previously taken \
       photos.  Proceed with deletion?";
		} else {
			dialog.message =
				"You previously entered a description of observed damage for this component.  \
       Changing this radio button option from 'Issue' to 'OK' will delete your previously entered \
       text.  Proceed with deletion?";
		}
		dialog.present(dialog);
		consoleMsg("Alert data = ", dialog.data);
		dialog.data = { textId: textId, divId: divId, textArea: textArea };
	}
}

// Get last key stored in Ionic Storage
async function getLastKey() {
	const keys = await store.keys(); // Get all keys from storage
	if (keys.length > 0) {
		const lastKey = keys[keys.length - 1]; // Get the last key
		consoleMsg("Last key:", lastKey);
		return lastKey;
	} else {
		consoleMsg("No keys found in storage.");
		return null;
	}
}

function removeDate(ionDate, div) {
	consoleMsg("Date alert OK clicked");
	ionDate.value = undefined;
	hideDiv(div);
}

/* Function to check one checkmark from each group so don't manually need to check them.
 While this function works, it iterates through each check mark and toggles the state to 'No'.
 I'd rather check the first radio in each group and then move on to next radio button group,
 but everything I've tried hasn't worked.  Think more about this next week, but adding as a stub on each page
 for now.*/

function selectRadios(nodeList) {
	if (app.appOptions.checkRadios) {
		for (const node of nodeList) {
			if ((app.currentPage === "page3") | (app.currentPage === "page8")) {
				node.value = "Yes";
			} else {
				node.value = "OK";
			}
		}
	}
}

function selectFile(uploadDiv) {
	let input = document.createElement("input");
	document.getElementById("bannerImg").style.display = "block";
	input.type = "file";
	input.accept = ".jpeg,.jpg,.png";
	input.onchange = (_) => {
		// you can use this method to get file and perform respective operations
		let files = Array.from(input.files);
		if (files && files[0]) {
			var reader = new FileReader();

			reader.onload = function (e) {
				document.getElementById("bannerImg").src = e.target.result;
				consoleMsg("Saved image");
			};
		}

		reader.readAsDataURL(input.files[0]);
		//consoleMsg(files);
	};
	input.click();
}

function getBase64Image(img) {
	var canvas = document.createElement("canvas");
	canvas.width = img.width;
	canvas.height = img.height;

	var ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);

	var dataURL = canvas.toDataURL("image/png");

	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

function localStorageSpace() {
	var allStrings = "";
	for (var key in window.localStorage) {
		if (window.localStorage.hasOwnProperty(key)) {
			allStrings += window.localStorage[key];
		}
	}
	return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) + " KB" : "Empty (0 KB)";
}

function showDialog(id) {
	let dialog = document.getElementById(id);
	dialog.show();
}

function hideDialog(id, val) {
	consoleMsg("Dialog value = " + val);
	document.getElementById(id).hide();
	return val;
}

/* 08/01/24: New function added to check Internet connection */
const checkOnlineStatus = async () => {
	if (!navigator.onLine) {
		return false; // Device is not connected to a network
	}
	try {
		const online = await fetch("https://jsonplaceholder.typicode.com/todos/1", { cache: "no-store" });
		return online.status >= 200 && online.status < 300; // either true or false
	} catch (err) {
		return false; // definitely offline
	}
};

const updateOfflineStatus = async (elem) => {
	const myDiv = document.getElementById("offline-div");
	const ionContent = document.getElementById(elem);
	//consoleMsg("ION CONTENT: ", ionContent);

	const refresher = ionContent.firstElementChild;
	//consoleMsg("OFFLINE DIV: ", myDiv);
	const result = await checkOnlineStatus();
	if (myDiv) {
		if (result) {
			myDiv.classList.add("hidden");
			ionContent.classList.remove("offline-visible");
			refresher.classList.remove("offline-visible");
		} else {
			myDiv.classList.remove("hidden");
			ionContent.classList.add("offline-visible");
			refresher.classList.add("offline-visible");
		}
	}
};
