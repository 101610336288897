/*
********************************************************************************
File for importing images so that they can be optimized by Vite.  Used in 
damage-map.js and navigation.js.
********************************************************************************
*/
// Import all project images so image references in dynamic HTML can be updated with hashes
import legendImg from "/assets/img/legend.png";
import car1000 from "/assets/img/car-1000.png";
import car2000 from "/assets/img/car-2000.png";
import logoImg300 from "/assets/img/logo-300.png";
import logoImg900 from "/assets/img/logo-900.png";
import crosshairs from "/assets/img/crosshairs-256.png";
import exclamationCircle from "/assets/img/exclamation-circle-256.png";
import exclamationTriangle from "/assets/img/exclamation-triangle-256.png";
import questionCircle from "/assets/img/question-circle-256.png";
import timesCircle from "/assets/img/times-circle-256.png";
import carFront from "/assets/img/car-front.png";
import carBack from "/assets/img/car-back.png";
import carDriverProfile from "/assets/img/car-driver-profile.png";
import carDriverSide from "/assets/img/car-driver-side.png";
import carPassengerProfile from "/assets/img/car-passenger-profile.png";
import carPassengerSide from "/assets/img/car-passenger-side.png";
import carTop from "/assets/img/car-top.png";
import carTrunk from "/assets/img/car-trunk.png";
import carInteriorFront from "/assets/img/car-interior-front.png";
import carInteriorRear from "/assets/img/car-interior-rear.png";
import carOdomometer from "/assets/img/car-odometer.png";
import carVin from "/assets/img/car-vin-label.png";
import favIconLight16 from "/assets/img/favicon-light-16.png";
import favIconLight32 from "/assets/img/favicon-light-32.png";
import favIconDark16 from "/assets/img/favicon-dark-16.png";
import favIconDark32 from "/assets/img/favicon-dark-32.png";

export { updateImagePaths, imgMapping, legendMapping, favIconLight16, favIconLight32, favIconDark16, favIconDark32 };

console.log("isDevelopment:", app.appOptions.isDevelopment);
// Variable to map hard-coded image references to hashed image paths
const imgMapping = {
	"legend.png": legendImg,
	"car-1000.png": car1000,
	"car-2000.png": car2000,
	"logo-300.png": logoImg300,
	"logo-900.png": logoImg900,
	"crosshairs-256.png": crosshairs,
	"exclamation-circle-256.png": exclamationCircle,
	"exclamation-triangle-256.png": exclamationTriangle,
	"question-circle-256.png": questionCircle,
	"times-circle-256.png": timesCircle,
	"car-front.png": carFront,
	"car-back.png": carBack,
	"car-driver-profile.png": carDriverProfile,
	"car-driver-side.png": carDriverSide,
	"car-passenger-profile.png": carPassengerProfile,
	"car-passenger-side.png": carPassengerSide,
	"car-top.png": carTop,
	"car-trunk.png": carTrunk,
	"car-interior-front.png": carInteriorFront,
	"car-interior-rear.png": carInteriorRear,
	"car-odometer.png": carOdomometer,
	"car-vin-label.png": carVin,
};

const legendMapping = { "legend.png": legendImg, "car-1000.png": car1000, "logo-900.png": logoImg900 };

function updateImagePaths(content, images) {
	// Only update production image paths
	if (!app.appOptions.isDevelopment) {
		Object.keys(images).forEach((key) => {
			const regex = new RegExp(`/assets/img/${key}`, "g");
			content = content.replace(regex, imgMapping[key]);
		});
	}
	return content;
}

// Make images available for import in other modules
// prettier-ignore
export { 
	legendImg, 
	car1000, 
	car2000, 
	//logoImg186, 
	logoImg300, 
	//logoImg558, 
	logoImg900,
	crosshairs, 
	exclamationCircle, 
	exclamationTriangle, 
	questionCircle, 
	timesCircle 
};
