/*
********************************************************************************
Signature pad functions that were previously in app.js.  Moved all variables 
previously defined here to the Signature class definition.  To get working 
properly, I had to pass the variables as arguments to the resizeCanvas function.
********************************************************************************
*/

// Adjust canvas coordinate space taking into account pixel ratio,
// to make it look crisp on mobile devices.
// This also causes canvas to be cleared.
import { consoleMsg } from "./utility.js";

export function resizeCanvas(canvas, signaturePad) {
	consoleMsg("New resize canvas function called...");
	// If there's no signature, just resize the canvas and exit
	if (signaturePad.isEmpty()) {
		adjustCanvasSize(canvas);
		return;
	}

	// Save the current signature as an image before resizing
	let signatureData = signaturePad.toDataURL();

	// Store previous canvas dimensions
	let prevWidth = canvas.width;
	let prevHeight = canvas.height;

	// Resize the canvas
	adjustCanvasSize(canvas);

	// Restore the signature with the correct scaling
	let img = new Image();
	img.src = signatureData;
	img.onload = () => {
		let ctx = canvas.getContext("2d");

		// Calculate scale factors
		let scaleX = canvas.width / prevWidth;
		let scaleY = canvas.height / prevHeight;

		// Maintain aspect ratio (use the smallest scale factor)
		let scale = Math.min(scaleX, scaleY);

		// Calculate new width/height while maintaining aspect ratio
		let newWidth = img.width * scale;
		let newHeight = img.height * scale;

		// Center the signature on the resized canvas
		let offsetX = (canvas.width - newWidth) / 2;
		let offsetY = (canvas.height - newHeight) / 2;

		// Draw the resized signature
		ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);
	};
}

// Function to adjust canvas size while maintaining resolution clarity
function adjustCanvasSize(canvas) {
	consoleMsg("adjustCanvasSize function called...");
	var ratio = Math.max(window.devicePixelRatio || 1, 1);
	canvas.width = canvas.offsetWidth * ratio;
	canvas.height = canvas.offsetHeight * ratio;
	canvas.getContext("2d").scale(ratio, ratio);
}

/*
export function resizeCanvas(canvas, signaturePad) {
	// When zoomed out to less than 100%, for some very strange reason,
	// some browsers report devicePixelRatio as less than 1
	// and only part of the canvas is cleared then.
	var ratio = Math.max(window.devicePixelRatio || 1, 1);

	// This part causes the canvas to be cleared
	canvas.width = canvas.offsetWidth * ratio;
	canvas.height = canvas.offsetHeight * ratio;
	canvas.getContext("2d").scale(ratio, ratio);

	// This library does not listen for canvas changes, so after the canvas is automatically
	// cleared by the browser, SignaturePad#isEmpty might still return false, even though the
	// canvas looks empty, because the internal data of this library wasn't cleared. To make sure
	// that the state of this library is consistent with visual state of the canvas, you
	// have to clear it manually.
	signaturePad.clear();
	//consoleMsg('MD RESIZE CANVAS CALLED...');
}*/

export function download(dataURL, filename) {
	if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1) {
		window.open(dataURL);
	} else {
		var blob = dataURLToBlob(dataURL);
		var url = window.URL.createObjectURL(blob);

		var a = document.createElement("a");
		a.style = "display: none";
		a.href = url;
		a.download = filename;

		document.body.appendChild(a);
		a.click();

		window.URL.revokeObjectURL(url);
	}
}

// One could simply use Canvas#toBlob method instead, but it's just to show
// that it can be done using result of SignaturePad#toDataURL.
export function dataURLToBlob(dataURL) {
	// Code taken from https://github.com/ebidel/filer.js
	var parts = dataURL.split(";base64,");
	var contentType = parts[0].split(":")[1];
	var raw = window.atob(parts[1]);
	var rawLength = raw.length;
	var uInt8Array = new Uint8Array(rawLength);

	for (var i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
}
