/*
********************************************************************************
Supplemental functions related to PDF generation using jsPDF and jsPDF-AutoTable 
libraries.  Moved to separate file to declutter main function that actually 
generates the output PDF.
********************************************************************************
*/

import { consoleMsg } from "./utility.js";
export { addDataTable, addDetailsTable, formatTable, addCarImagesTable, addFullSizeImages, calculateOffset, addFooter };

// Function to retrieve form data from Ionic storage
async function getPages(...pageKeys) {
	try {
		// Use Promise.all to retrieve all stored objects concurrently
		const pages = await Promise.all(pageKeys.map((key) => store.get(key)));
		return pages;
	} catch (error) {
		console.error("Error retrieving pages from storage: ", error);
		return [];
	}
}

function addCarImagesTable(doc, cars, margin, yPosition, fullSizeImagePages) {
	const cols = 4;
	const cellSpacing = 2;
	const pageWidth = Math.round(doc.internal.pageSize.width);
	const cellWidth = (pageWidth - 2 * margin - (cols - 1) * cellSpacing) / cols;

	// Filter valid images
	const validImages = cars.filter((img) => img && img.photo && img.id);

	if (validImages.length === 0) {
		console.warn("No valid images found for the table.");
		return;
	}

	const tableBody = [];
	for (let i = 0; i < validImages.length; i += cols) {
		const idRow = [];
		const imageRow = [];

		for (let c = 0; c < cols; c++) {
			const imgIndex = i + c;
			if (imgIndex < validImages.length) {
				const image = validImages[imgIndex];
				const aspectRatio = image.ratio || 0.75;
				const cellHeight = cellWidth * aspectRatio;

				// Add ID row with the actual `id` from the cars array
				idRow.push({ content: `ID: ${image.id}`, colSpan: 1, styles: { halign: "center", fontStyle: "bold" } });

				// Add image row
				imageRow.push({ content: "", styles: { minCellHeight: cellHeight } });
			} else {
				idRow.push("");
				imageRow.push("");
			}
		}

		tableBody.push(idRow);
		tableBody.push(imageRow);
	}

	doc.autoTable({
		body: tableBody,
		theme: "grid",
		startY: yPosition,
		tableWidth: "auto",
		styles: { fontSize: 8, valign: "middle" },
		margin: { left: margin, right: margin },
		didDrawCell: function (data) {
			// Ensure we are in the correct row (image row, not ID row)
			const imgIndex = Math.floor(data.row.index / 2) * cols + data.column.index;
			if (data.row.index % 2 === 1 && imgIndex < validImages.length) {
				const image = validImages[imgIndex];
				const xPos = data.cell.x + cellSpacing / 2;
				const yPos = data.cell.y + cellSpacing / 2;
				const imgW = cellWidth - cellSpacing;
				const imgH = imgW * image.ratio;

				doc.addImage(image.photo, "JPEG", xPos, yPos, imgW, imgH);

				fullSizeImagePages.push({
					image: image.photo,
					ratio: image.ratio,
					page: null,
					thumbX: xPos,
					thumbY: yPos,
					thumbW: imgW,
					thumbH: imgH,
				});
			}
		},
	});

	return doc.lastAutoTable.finalY + 5;
}

/*
function addCarImagesTable(doc, cars, margin, yPosition, fullSizeImagePages) {
	consoleMsg("Cars array: ", cars);
	const cols = 4;
	const rows = 3;
	const cellSpacing = 2; // Space between cells
	const pageWidth = Math.round(doc.internal.pageSize.width);
	const cellWidth = (pageWidth - 2 * margin - (cols - 1) * cellSpacing) / cols;

	// Ensure only valid images are used
	const validImages = cars.filter((img) => img && img.photo);

	if (validImages.length === 0) {
		console.warn("No valid images found for the table.");
		return;
	}

	const tableBody = [];
	for (let r = 0; r < rows; r++) {
		const row = [];
		for (let c = 0; c < cols; c++) {
			const imgIndex = r * cols + c;
			if (imgIndex < validImages.length) {
				const aspectRatio = validImages[imgIndex].ratio || 0.75; // Default aspect ratio
				const cellHeight = cellWidth * aspectRatio;
				row.push({ content: "", styles: { minCellHeight: cellHeight } });
			} else {
				row.push("");
			}
		}
		tableBody.push(row);
	}

	// Adjust start position to prevent overlapping
	if (yPosition + rows * (cellWidth * 0.75) + margin > doc.internal.pageSize.height) {
		doc.addPage();
		yPosition = margin;
	}

	doc.autoTable({
		body: tableBody,
		theme: "grid",
		startY: yPosition,
		tableWidth: "auto",
		styles: { fontSize: 8, valign: "middle" },
		margin: { left: margin, right: margin },
		didDrawCell: function (data) {
			const imgIndex = data.row.index * cols + data.column.index;
			if (imgIndex < validImages.length && validImages[imgIndex]) {
				try {
					const xPos = data.cell.x + cellSpacing / 2;
					const yPos = data.cell.y + cellSpacing / 2;
					const imgW = cellWidth - cellSpacing;
					const imgH = imgW * validImages[imgIndex].ratio; // Maintain aspect ratio

					doc.addImage(validImages[imgIndex].photo, "JPEG", xPos, yPos, imgW, imgH);

					// Store coordinates for correct link placement
					fullSizeImagePages.push({
						image: validImages[imgIndex].photo,
						ratio: validImages[imgIndex].ratio,
						page: null,
						thumbX: xPos,
						thumbY: yPos,
						thumbW: imgW,
						thumbH: imgH,
					});
				} catch (error) {
					console.error("Error adding image:", error);
				}
			}
		},
	});
	consoleMsg("Full size image array: ", fullSizeImagePages);
	return doc.lastAutoTable.finalY + 5;
}*/

function addFullSizeImages(doc, fullSizeImagePages, margin) {
	const pageWidth = Math.round(doc.internal.pageSize.width);

	if (fullSizeImagePages.length === 0) {
		console.warn("⚠️ No full-size images to add.");
		return;
	}

	// Step 1: Add full-size images on new pages
	fullSizeImagePages.forEach((entry) => {
		doc.addPage();
		entry.pageNumber = doc.internal.getNumberOfPages(); // Store page number

		const fullImgWidth = pageWidth - 2 * margin;
		const fullImgHeight = fullImgWidth * entry.ratio;

		doc.addImage(entry.image, "JPEG", margin, margin, fullImgWidth, fullImgHeight);
	});

	// Step 2: Return to first page and add overlay links at correct positions
	doc.setPage(1);

	fullSizeImagePages.forEach((entry) => {
		if (!entry.thumbX || !entry.thumbY || !entry.pageNumber) {
			console.error("❌ Skipping link creation due to missing coordinates.");
			return;
		}

		// Ensure link overlays the thumbnail properly
		doc.link(entry.thumbX, entry.thumbY, entry.thumbW, entry.thumbH, { pageNumber: entry.pageNumber });
	});
}

function addDetailsTable(doc, data, margin, yPosition) {
	const body = [];
	const dataKeys = Object.keys(data);
	const dataValues = Object.values(data);
	// Define the headers and data
	const headers = [];
	const values = [];
	for (let i = 0; i < dataKeys.length; i++) {
		headers.push(dataKeys[i]);
		values.push(dataValues[i]);
	}
	consoleMsg("Data keys length: ", dataKeys.length);
	for (let i = 0; i < headers.length; i += 2) {
		body.push([dataKeys[i], dataValues[i], dataKeys[i + 1], dataValues[i + 1]]);
	}
	formatTable(doc, body, margin, yPosition);
}

function formatTable(doc, body, margin, yPosition) {
	doc.autoTable({
		body: body,
		theme: "grid",
		startY: yPosition,
		tableWidth: "auto",
		styles: {
			fontSize: 8,
			valign: "middle",
			cellPadding: { top: 2, bottom: 2, left: 2, right: 2 }, // Adjust padding
			minCellHeight: 6, // Ensures enough height for centering
		},
		margin: { left: margin, right: margin },
		didParseCell: function (data) {
			if (data.section === "body") {
				if (data.cell.raw && data.cell.raw.styles && data.cell.raw.styles.fillColor) {
					data.cell.styles.fillColor = data.cell.raw.styles.fillColor;
				} else if (data.column.index % 2 === 0) {
					data.cell.styles.fillColor = [240, 240, 240]; // Light gray for even columns
				} else {
					data.cell.styles.fillColor = [255, 255, 255]; // White for odd columns
				}
			}
		},
	});
}
/*
function formatTable(doc, body, margin, yPosition) {
	doc.autoTable({
		body: body,
		theme: "grid",
		startY: yPosition,
		tableWidth: "auto",
		styles: { fontSize: 8, valign: "middle", minCellWidth: 15 },
		margin: { left: margin, right: margin },
		didParseCell: function (data) {
			if (data.section === "body") {
				// Check to see if the cell has a fillColor explicitly defined (e.g., textarea values)
				if (data.cell.raw && data.cell.raw.styles && data.cell.raw.styles.fillColor) {
					data.cell.styles.fillColor = data.cell.raw.styles.fillColor;
				} else if (data.column.index % 2 === 0) {
					data.cell.styles.fillColor = [240, 240, 240]; // Light gray for even columns
				} else {
					data.cell.styles.fillColor = [255, 255, 255]; // White for odd columns
				}
			}
		},
	});
}*/

function setPageTitle(page) {
	let string = "";
	switch (page) {
		case "2":
			string = "Exterior Inspection";
			break;
		case "3":
			string = "Glovebox Check";
			break;
		case "4":
			string = "Interior Check";
			break;
		case "5":
			string = "Road Test";
			break;
		case "6":
			string = "Lights Check";
			break;
		case "7":
			string = "Fluid Inspection";
			break;
		case "8":
			string = "Tire Inspection";
			break;
	}
	return string;
}

function addDataTable(doc, data, margin, yPosition, currentPage) {
	const body = [];
	const headers = [];
	const values = [];
	//consoleMsg("addDataTable called...");
	//consoleMsg("Data = ", data);
	const keys = Object.keys(data[currentPage]);
	//consoleMsg("keys = ", keys);
	for (let i = 0; i < keys.length; i++) {
		const row = data[currentPage][i + 1];
		const page = row.page.slice(-1);
		//consoleMsg("Page = ", page);
		headers.push(row.string);
		values.push(row.value);
	}

	//consoleMsg("Headers length =", headers.length);
	for (let i = 0; i < headers.length; i++) {
		if (headers[i] === "") {
			// Check for empty string value, which indicates an ion textarea problem description
			body.push([{ content: "Problem details: " + values[i], colSpan: 2, styles: { fillColor: [255, 255, 255] } }]);
			// Check for problems and style value cell accordingly
		} else if ((values[i] === "no") | (values[i] === "low")) {
			body.push([
				{ content: [headers[i]], colSpan: 1, styles: { cellWidth: 120 } },
				{
					content: [values[i]],
					colSpan: 1,
					styles: { fillColor: [249, 229, 231], textColor: [164, 10, 30] },
				},
			]);
		} else {
			body.push([
				{ content: [headers[i]], colSpan: 1, styles: { cellWidth: 120 } },
				{ content: [values[i]], colSpan: 1 },
			]);
		}
	}
	formatTable(doc, body, margin, yPosition);
}

function addTable(doc, data, margin, yPosition, cols = 4) {
	const body = [];
	const dataKeys = Object.keys(data);
	const dataValues = Object.values(data);
	// Define the headers and data
	const headers = [];
	const values = [];
	for (let i = 0; i < dataKeys.length; i++) {
		headers.push(dataKeys[i]);
		values.push(dataValues[i]);
	}
	consoleMsg("Data keys length: ", dataKeys.length);
	if (cols === 4) {
		for (let i = 0; i < headers.length; i += 2) {
			body.push([dataKeys[i], dataValues[i], dataKeys[i + 1], dataValues[i + 1]]);
		}
	} else {
		for (let i = 0; i < headers.length; i++) {
			consoleMsg("Data keys: ", dataKeys[i]);

			if (dataKeys[i].includes("text") || dataKeys[i] === "") {
			} else {
				body.push([dataKeys[i], dataValues[i]]);
			}
		}
	}

	doc.autoTable({
		body: body,
		theme: "grid",
		startY: yPosition,
		tableWidth: "auto",
		styles: { fontSize: 8, valign: "middle", minCellWidth: 15 },
		margin: { left: margin, right: margin },
		didParseCell: function (data) {
			if (data.section === "body") {
				// Check to see if the cell has a fillColor explicitly defined (e.g., textarea values)
				if (data.cell.raw && data.cell.raw.styles && data.cell.raw.styles.fillColor) {
					data.cell.styles.fillColor = data.cell.raw.styles.fillColor;
				} else if (data.column.index % 2 === 0) {
					data.cell.styles.fillColor = [240, 240, 240]; // Light gray for even columns
				} else {
					data.cell.styles.fillColor = [255, 255, 255]; // White for odd columns
				}
			}
		},
	});
}

// Define a function to calculate the Y offset
function calculateOffset(doc, lineHeight) {
	let offset = doc.getFontSize() * lineHeight * 0.352778; // 0.352778 is the conversion factor from mm to pixels;
	offset = parseFloat(offset.toFixed(2));
	//consoleMsg("yOffset = ", offset);
	return offset;
}

function addFooter(doc, margin, pageWidth, pageHeight) {
	const totalPages = doc.getNumberOfPages();
	const currentDate = new Date().toLocaleDateString();

	for (let i = 1; i <= totalPages; i++) {
		doc.setPage(i);
		const footerTitle = "Vehicle Inspection Summary";
		const footerPage = `Page ${i} of ${totalPages}`;
		const footerDate = `${currentDate}`;
		doc.text(footerTitle, margin, pageHeight - 10, null, null, "left");
		doc.text(footerPage, pageWidth / 2, pageHeight - 10, null, null, "center");
		doc.text(footerDate, pageWidth - margin, pageHeight - 10, null, null, "right");
	}
}

async function openPDF(fileName) {
	// Get the file URI
	const fileUri = await Filesystem.getUri({
		path: fileName,
		directory: Directory.Documents,
	});

	// Open the PDF using the Browser API
	await Browser.open({ url: fileUri.uri });
}

async function setTitle(titleString) {
	const title = titleString;
	doc.title = title;
}
