/*
********************************************************************************
NOT CURRENTLY USING!!  This file was an attempt to import only those Ionic
components actually in use by my project.  However, app had rendering problems:
inspection page source code in dev tools showed that the HTML seemed correct
for each dynamically loaded page template, but for some reason none of the 
Ionic hydration classes were being applied so app rendered with a white screen
and invisible content.  Revisit this later to see if it can be made to work.
********************************************************************************
*/
import { IonDatetime } from '@ionic/core/components/ion-datetime.js';
import { IonApp } from '@ionic/core/components/ion-app.js';
import { IonRouter } from '@ionic/core/components/ion-router.js';
import { IonRouterLink } from '@ionic/core/components/ion-router-link.js';
import { IonRoute } from '@ionic/core/components/ion-route.js';
import { IonRouterOutlet } from '@ionic/core/components/ion-router-outlet.js';
import { IonAlert } from '@ionic/core/components/ion-alert.js';
import { IonButton } from '@ionic/core/components/ion-button.js';
import { IonCard } from '@ionic/core/components/ion-card.js';
import { IonCheckbox } from '@ionic/core/components/ion-checkbox.js';
import { IonContent } from '@ionic/core/components/ion-content.js';
import { IonItem } from '@ionic/core/components/ion-item.js';
import { IonRow } from '@ionic/core/components/ion-row.js';
import { IonCol } from '@ionic/core/components/ion-col.js';
import { IonIcon } from '@ionic/core/components/ion-icon.js';
import { IonInput } from '@ionic/core/components/ion-input.js';
import { IonTextarea } from '@ionic/core/components/ion-textarea.js';
import { IonLabel } from '@ionic/core/components/ion-label.js';
import { IonList } from '@ionic/core/components/ion-list.js';
import { IonListHeader } from '@ionic/core/components/ion-list-header.js';
import { IonMenu } from '@ionic/core/components/ion-menu.js';
import { IonMenuToggle } from '@ionic/core/components/ion-menu-toggle.js';
import { IonMenuButton } from '@ionic/core/components/ion-menu-button.js';
import { IonModal } from '@ionic/core/components/ion-modal.js';
import { IonRadio } from '@ionic/core/components/ion-radio.js';
import { IonRadioGroup } from '@ionic/core/components/ion-radio-group.js';
import { IonSelect } from '@ionic/core/components/ion-select.js';
import { IonSelectOption } from '@ionic/core/components/ion-select-option.js';
import { IonToggle } from '@ionic/core/components/ion-toggle.js';
import { IonToolbar } from '@ionic/core/components/ion-toolbar.js';
import { IonHeader } from '@ionic/core/components/ion-header.js';
import { IonFooter } from '@ionic/core/components/ion-footer.js';
import { IonTitle } from '@ionic/core/components/ion-title.js';
import { IonButtons } from '@ionic/core/components/ion-buttons.js';
import { IonBackButton } from '@ionic/core/components/ion-back-button.js';
import { IonText } from '@ionic/core/components/ion-text.js';

console.log(customElements.get('ion-button')); // Should return the constructor if registered

//customElements.define('ion-datetime', IonDatetime);
//customElements.define('ion-textarea', IonTextarea);
//customElements.define('ion-image', IonImg);
const components = [
  { tag: 'ion-datetime', component: IonDatetime },
  { tag: 'ion-app', component: IonApp },
  { tag: 'ion-router', component: IonRouter },
  { tag: 'ion-router-link', component: IonRouterLink },
  { tag: 'ion-route', component: IonRoute },
  { tag: 'ion-router-outlet', component: IonRouterOutlet },
  { tag: 'ion-alert', component: IonAlert },
  { tag: 'ion-button', component: IonButton },
  { tag: 'ion-card', component: IonCard },
  { tag: 'ion-checkbox', component: IonCheckbox },
  { tag: 'ion-content', component: IonContent },
  { tag: 'ion-item', component: IonItem },
  { tag: 'ion-row', component: IonRow },
  { tag: 'ion-col', component: IonCol },
  { tag: 'ion-icon', component: IonIcon },
  { tag: 'ion-input', component: IonInput },
  { tag: 'ion-textarea', component: IonTextarea },
  { tag: 'ion-label', component: IonLabel },
  { tag: 'ion-list', component: IonList },
  { tag: 'ion-list-header', component: IonListHeader },
  { tag: 'ion-menu', component: IonMenu },
  { tag: 'ion-menu-toggle', component: IonMenuToggle },
  { tag: 'ion-menu-button', component: IonMenuButton },
  { tag: 'ion-modal', component: IonModal },
  { tag: 'ion-radio', component: IonRadio },
  { tag: 'ion-radio-group', component: IonRadioGroup },
  { tag: 'ion-select', component: IonSelect },
  { tag: 'ion-select-option', component: IonSelectOption },
  { tag: 'ion-toggle', component: IonToggle },
  { tag: 'ion-toolbar', component: IonToolbar },
  { tag: 'ion-header', component: IonHeader },
  { tag: 'ion-footer', component: IonFooter },
  { tag: 'ion-title', component: IonTitle },
  { tag: 'ion-buttons', component: IonButtons },
  { tag: 'ion-back-button', component: IonBackButton },
  { tag: 'ion-text', component: IonText },
];

console.log('Initializing Ionic components...');

components.forEach(({ tag, component }) => {
  if (!customElements.get(tag)) {
    customElements.define(tag, component);
    console.log(`Registered: ${tag}`);
  } else {
    //console.warn(`Skipped: ${tag} (already registered)`);
  }
});
