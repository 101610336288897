/*
********************************************************************************
Functions related to PDF generation using jsPDF and jsPDF-AutoTable libraries
********************************************************************************
*/

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Browser } from "@capacitor/browser";

import { consoleMsg } from "./utility.js";
import { combinedVehicleDetails, updateCombinedVehicleDetails } from "@app/classes.js";
import { addDataTable, addDetailsTable, addCarImagesTable, addFullSizeImages, calculateOffset, addFooter } from "./pdf-functions.js";

// TO DO: Replace with data retrieved from NAVS API

let fakeData = {
	driver: "Connor Castro",
	vehicle: "2024 BMW 3 Series",
	manufacturer: "BMW",
};

export async function generateInspectionPDF() {
	// Retrieve vehicle data from Ionic storage
	await updateCombinedVehicleDetails();

	// Define variables to store inspection photos
	// prettier-ignore
	const cars = [
    await store.get('car-front'), 
    await store.get('car-driver-side'), 
    await store.get('car-passenger-side'),
    await store.get('car-back'),
    await store.get('car-interior-front'),
    await store.get('car-interior-rear'),
    await store.get('car-vin'),
    await store.get('car-top'),
    await store.get('car-driver-profile'),
    await store.get('car-passenger-profile'),
    await store.get('car-trunk'),
    await store.get('car-odometer')
  ];

	consoleMsg("Cars array: ", cars);

	const vehicleInfo = combinedVehicleDetails.vehicleInfo;
	const contactInfo = combinedVehicleDetails.contactInfo;
	const loanInfo = combinedVehicleDetails.loanInfo;
	/*consoleMsg("Vehicle info: ", vehicleInfo);
	consoleMsg("Contact info: ", contactInfo);
	consoleMsg("Loan info: ", loanInfo);*/

	// Define variables to store page data for insertion into AutoTable
	const page2 = await store.get("page2");
	const page3 = await store.get("page3");
	const page4 = await store.get("page4");
	const page5 = await store.get("page5");
	const page6 = await store.get("page6");
	const page7 = await store.get("page7");
	const page8 = await store.get("page8");
	// Set line height multiplier
	const lineHeight = 1.35;

	const doc = new jsPDF({
		unit: "mm",
		lineHeight: lineHeight,
	});

	const margin = 24;
	const fontSize = 10;
	const pageWidth = Math.round(doc.internal.pageSize.width);
	const pageHeight = Math.round(doc.internal.pageSize.height);
	const maxLineWidth = pageWidth - 2 * margin;
	const charSpacing = 0; // Default = 0
	const sectionBreak = 5; // Variable to add spacing between sections
	const pages = ["page2", "page3", "page4", "page5", "page6", "page7", "page8"];

	// Set initial Y position and offset
	let yPosition = 10;
	let yOffset = 0;

	// Set metadata
	doc.setProperties({
		title: "Vehicle Inspection Summary",
		subject: "Summary of vehicle inspection",
		author: "Page One Automotive",
		keywords: "inspection, vehicle, summary, " + fakeData.manufacturer,
		creator: "jsPDF",
		custom: {
			driver: fakeData.driver,
		},
	});

	doc.setCharSpace(charSpacing);

	// Fetch images from Ionic storage
	const legendImage = await store.get("legend-image");
	const damageImage = await store.get("damage-image");
	const signatureImage = await store.get("signature");
	const logoImage = await store.get("logo-image");

	/*consoleMsg("Logo image:", logoImage);
	consoleMsg("Legend image:", legendImage);
	consoleMsg("Damage image:", damageImage);
	consoleMsg("Signature image:", signatureImage);*/

	// X = width; Y = height.  To move down the page, increase Y.  To move right, increase X.
	//doc.addImage(logoImage, "PNG", (210 - 90) / 2, yPosition, 45, 11.4);
	doc.addImage(logoImage, "PNG", margin, yPosition, 45, 11.4);

	/* Not sure why, but need to add an additional 4 pixels to logo height to get text to offset 
	properly: investigate */
	//yPosition += 29.8;
	yPosition += 22.4;

	// Add title
	let title = "Vehicle Inspection Summary";

	doc.setFontSize(18);
	doc.setFont("helvetica", "bold");
	doc.setTextColor(128);
	let xPosition = pageWidth / 2; // Calculate the horizontal position to center the text
	doc.text(title, xPosition, yPosition, null, null, "center");

	// Calculate y offset: font size * line height / pixes per mm
	yOffset = calculateOffset(doc, lineHeight);
	// Add the offset to the current Y position to move down the page
	yPosition += yOffset;

	// Add summary
	doc.setFontSize(12);
	title = "Vehicle Passed Inspection: Release Issued";
	xPosition = pageWidth / 2;
	doc.text(title, xPosition, yPosition, null, null, "center");

	// Reset text color to black
	doc.setTextColor("#000000");

	// Recalculate offset and add to current yPosition
	yOffset = calculateOffset(doc, lineHeight);
	yPosition += yOffset;

	doc.setFont("Helvetica", "normal");

	title = "Inspection Details";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	// Set y position of inserted text.  Previously had set to 4, but text was too high.  Apparently JSPdf inserts text from baseline
	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	title = "Vehicle Information";
	doc.setFillColor(169);
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	addDetailsTable(doc, vehicleInfo, margin, yPosition);

	// Get the final Y position after the table is drawn
	let finalY = doc.lastAutoTable.finalY;

	consoleMsg("y position after info table: ", finalY);

	title = "Loan Information";

	yPosition = finalY;
	doc.setFillColor(169);
	doc.setTextColor(255);
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Update yPosition for the next section
	//yPosition = finalY + sectionBreak; // Add some padding after the table

	// Reset text color to black
	doc.setTextColor(0);

	addDetailsTable(doc, loanInfo, margin, yPosition);

	// Get the final Y position after the table is drawn
	finalY = doc.lastAutoTable.finalY;
	yPosition = finalY;
	consoleMsg("y position after loan table: ", finalY);

	// Calculate the height of the table
	//tableHeight = finalY - yPosition;

	consoleMsg("y position after info table: ", finalY);

	title = "Contact Information";

	yPosition = finalY;
	doc.setFillColor(169);
	doc.setTextColor(255);
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	addDetailsTable(doc, contactInfo, margin, yPosition);

	// Get the final Y position after the table is drawn
	finalY = doc.lastAutoTable.finalY;
	yPosition = finalY;
	consoleMsg("y position after contact table: ", finalY);

	title = "Inspection Photos";

	yPosition = finalY;
	doc.setFillColor(169);
	doc.setTextColor(255);
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	//consoleMsg("y position after contact table: ", finalY);

	// Define variable to store full size images - these will be added after all other content
	let fullSizeImagePages = [];

	//addCarImagesTable(doc, cars, margin, doc.lastAutoTable.finalY + 5, fullSizeImagePages);
	addCarImagesTable(doc, cars, margin, yPosition, fullSizeImagePages);

	/*
	// Get the final Y position after the table is drawn
	finalY = doc.lastAutoTable.finalY;

	// Calculate the height of the table
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak; // Add some padding after the table
	*/

	finalY = doc.lastAutoTable.finalY;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Exterior Inspection";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;
	consoleMsg("Exterior inspection y position: ", yPosition);

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	// Add legend & damage image

	// Recalculate offset and add to current yPosition
	yOffset = calculateOffset(doc, lineHeight);
	//yPosition += yOffset;

	// Define variables to scale image based on page margins
	let imgWidth = 180;
	//let imgHeight = 31;
	let imgHeight = 22;

	// Define variables to scale image based on page margins
	let scaleFactor = (pageWidth - 2 * margin) / imgWidth;

	if (legendImage) {
		// If yPosition + height of legend image + damage image exceeds page height, add a new page
		if (yPosition + margin + 106.6 > pageHeight) {
			doc.addPage();
			consoleMsg("Adding new page for legend image");
			yPosition = margin;
		}
		imgWidth *= scaleFactor;
		imgHeight *= scaleFactor;
		doc.addImage(legendImage, "PNG", margin, yPosition, imgWidth, imgHeight);
		yPosition += imgHeight;
	}

	if (damageImage) {
		imgHeight = 75.6 * scaleFactor;
		doc.addImage(damageImage, "PNG", margin, yPosition, imgWidth, imgHeight);
		//yPosition += imgHeight + sectionBreak;
		yPosition += imgHeight;
	}

	let currentPage = "page2";
	addDataTable(doc, page2, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	// Fetch all damage photos
	const keys = await store.keys();
	const damagePhotos = {};
	for (const key of keys) {
		//if (key.includes("photoRow") && !key.includes("-ratio")) {
		if (key.includes("photoRow")) {
			const photoObject = await store.get(key);
			const photo = photoObject.photo;
			const ratio = photoObject.ratio;
			damagePhotos[key] = { photo, ratio };
		}
	}

	// Add damage photos
	if (yPosition + margin + 75.6 > pageHeight) {
		doc.addPage();
		consoleMsg("Adding new page for damage photos");
		yPosition = margin;
	}

	/*doc.text("Damage Photos:", margin, yPosition);
	yOffset = calculateOffset(doc, lineHeight);
	consoleMsg("Damage photos text yOffset", yOffset);
	yPosition += yOffset;*/
	title = "Damage Photos";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");
	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);
	for (const key in damagePhotos) {
		consoleMsg("key", key);
		if (damagePhotos.hasOwnProperty(key)) {
			const { photo, ratio } = damagePhotos[key];
			const width = 180 * scaleFactor;
			const height = width * ratio; // Calculate height based on aspect ratio
			if (yPosition + height > pageHeight - 2 * margin) {
				doc.addPage();
				yPosition = margin;
			}
			doc.addImage(photo, "JPEG", margin, yPosition, width, height);
			consoleMsg("Adding damage photo at yPosition", yPosition);
			yPosition += height + sectionBreak;
			consoleMsg("Damage photo height = ", height + " Page height = ", pageHeight);
			consoleMsg("New yPosition after adding damage photo: ", yPosition);
		}
	}

	if (yPosition + 75 + margin > pageHeight) {
		doc.addPage();
		consoleMsg("Adding new page for signature image");
		yPosition = margin;
	}

	title = "Glovebox Check";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page3";
	addDataTable(doc, page3, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Interior Check";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page4";
	addDataTable(doc, page4, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Road Test";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page5";
	addDataTable(doc, page5, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Lights Check";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page6";
	addDataTable(doc, page6, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Fluid Inspection";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page7";
	addDataTable(doc, page7, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	title = "Tire Inspection";
	//title = "x".repeat(90);
	xPosition = pageWidth / 2;
	doc.setFontSize(8);
	doc.setFillColor(74, 131, 192);
	doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
	doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, "F");

	yPosition += 5;

	doc.text(title, xPosition, yPosition, null, null, "center");

	yPosition += 3;

	// Reset text color to black
	doc.setTextColor(0);

	currentPage = "page8";
	addDataTable(doc, page8, margin, yPosition, currentPage);

	finalY = doc.lastAutoTable.finalY;
	//tableHeight = finalY - yPosition;

	// Update yPosition for the next section
	yPosition = finalY + sectionBreak;

	// Add problems

	/*
	doc.setFontSize(12);
	title = "Problems";
	xPosition = pageWidth / 2;

	if (yPosition + margin > pageHeight) {
		doc.addPage();
		consoleMsg("Adding new page for legend image");
		yPosition = margin;
	}
	doc.text(title, xPosition, yPosition, null, null, "center");

	// Recalculate offset and add to current yPosition
	yOffset = calculateOffset(doc, lineHeight);
	yPosition += yOffset;

	let textLines = doc
		.setFont("Helvetica", "normal") //
		.setFontSize(fontSize)
		.setCharSpace(charSpacing)
		.splitTextToSize(myParagraph, maxLineWidth);

	const ptsPerMm = 0.352778;
	const oneLineHeight = fontSize * lineHeight * ptsPerMm;
	const textBlockHeight = textLines.length * oneLineHeight;
	consoleMsg("textLinesLenth", textLines.length);
	consoleMsg("textBlockHeight", textBlockHeight);

	
	yPosition += 2 * oneLineHeight;

	doc.text(textLines, margin, yPosition);

	yPosition += textBlockHeight;*/

	// Add digital signature

	if (yPosition + margin > pageHeight) {
		doc.addPage();
		consoleMsg("Adding new page for legend image");
		yPosition = margin;
	}
	doc.text("Digital Signature:", margin, yPosition);
	consoleMsg("Signature image position: ", yPosition);
	// Recalculate offset and add to current yPosition
	yOffset = calculateOffset(doc, lineHeight);
	yPosition += yOffset;
	if (signatureImage) {
		doc.addImage(signatureImage, "PNG", 14, yPosition, 100, 30);
		yPosition += 40;
	}

	// Add full size images to end of document
	addFullSizeImages(doc, fullSizeImagePages, margin);

	// Add footer to each page
	addFooter(doc, margin, pageWidth, pageHeight);

	// Save the PDF
	doc.save("inspection_summary.pdf");
	await store.set("inspection-summary", doc.output("datauristring"));

	// Convert the PDF to a Blob
	const pdfBlob = doc.output("blob");

	// Convert the Blob to Base64
	const reader = new FileReader();
	reader.readAsDataURL(pdfBlob);
	reader.onloadend = async function () {
		const base64data = reader.result.split(",")[1];

		// Save the Base64 encoded PDF to the file system
		const fileName = "inspection_summary.pdf";

		await Filesystem.writeFile({
			path: fileName,
			data: base64data,
			directory: Directory.Documents,
			encoding: Encoding.UTF8,
		});

		// Open the saved PDF
		//await openPDF(fileName);
	};
}
